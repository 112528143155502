<template>
  <perseu-card
    elevation="0"
    class="rounded-xl text-center"
    :title="title"
    title-center
    class-title="subtitle-1 secondary--text"
  >
    <slot />
  </perseu-card>
</template>

<script>
export default {
  name: "DashboardCard",
  props: {
    title: {
      type: String,
      required: true,
    },
  },
};
</script>

<style scoped></style>
